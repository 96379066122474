import { Card, IconButton, Tooltip } from "@mui/material"
import SpeedDialComponent from "../../../misc/SpeedDialComponent"
import AddIcon from '@mui/icons-material/Add';

export const MultipleResponseView = (
    {
        question,
        userQuestion,
        setEditMode,
        setReload,
        reload,
        handleAddResponse,
        screenWidth
    }:any
) => {

    

    return (
        <div className='q-container' style={{ display: "flex" }}>
            <Card raised elevation={24} sx={{ '&:hover': { scale: 1.5 }, bgcolor: "#FCFAFF", height: "80px" }} className='q-card'>
                <div className='q-body' style={{ fontSize: "15px" }}>
                    {question.body}
                </div>
                {screenWidth <= 1350 ?
                    ("") :
                    (
                        <div className='q-save' style={{ marginTop: "-1.2rem" }}>
                            <SpeedDialComponent setEditMode={setEditMode} setReload={setReload} reload={reload} userQuestion={userQuestion} />
                        </div>
                    )}
                <div className='q-response' style={{ paddingBottom: "0.75rem", fontSize: "24px" }}>
                    {userQuestion.response !== undefined ? (userQuestion.response) : ("loading")}
                </div>
            </Card>
            <Tooltip title="Add Response">
                <IconButton className='q-add' onClick={() => handleAddResponse(question, userQuestion)}>
                    <AddIcon sx={{ color: "lightgreen" }} className='q-add-btn' fontSize='large' />
                </IconButton>
            </Tooltip>
        </div>
    )
}