const _apiUrl = 'https://localhost:5001/api/Stripe'

//customer by email https://localhost:5001/api/Stripe/customer/{email}                  (works)
//subscription by email subscription by email https://localhost:5001/api/Stripe/subscriptions/byemail/{email}    (works)
//charges of $300 (lifetime charges) https://localhost:5001/api/Stripe/charges/byamount/{amount}                               (works)

//=========================================================================New
export const fetchStripeLifetimeCharges = async (email:string) => {
    const response = await fetch(`${_apiUrl}/charges/byamountandemail/${email}/30000`);
    if (response.ok) {
        const data = await response.json();
        // console.log(`Charges:`, data);
        return data
    } else {
        const error = await response.json();
        console.error(`Error fetching charges:`, error);
        return []
    }
};

export const fetchMonthlyCharges = async () => {
    const response = await fetch(`${_apiUrl}/charges/byamount/1000`);
    if (response.ok) {
        const data = await response.json();
        // console.log(`Charges:`, data);
        return data
    } else {
        const error = await response.json();
        console.error(`Error fetching charges:`, error);
        return null
    }
};

export const fetchYearlyCharges = async () => {
    const response = await fetch(`${_apiUrl}/charges/byamount/6000`);
    if (response.ok) {
        const data = await response.json();
        // console.log(`Charges:`, data);
        return data
    } else {
        const error = await response.json();
        console.error(`Error fetching charges:`, error);
        return null
    }
};

export const fetchStripeCustomersByEmail = async (email:string) => {
    const response = await fetch(`${_apiUrl}/customer/${email}`);
    if (response.ok) {
        const data = await response.json();
        // console.log(`Customers:`, data);
        return data
    } else {
        const error = await response.json();
        console.error(`Error fetching customers:`, error);
        return null
    }
};

export const fetchStripeSubscriptionsByEmail = async (email:string) => {
    const response = await fetch(`${_apiUrl}/subscriptions/byemail/${email}`);
    if (response.ok) {
        const data = await response.json();
        // console.log('Subscriptions:', data);
        return data
    } else {
        const error = await response.json();
        console.error('Error fetching subscriptions:', error);
        return null
    }
};



//=========================================================================Old

// export const fetchStripeCustomersByEmail = (email:string) => {
//     return fetch(_apiUrl+`/customers?email=${email}`, {
//         method: "GET",
//         headers: { 
//             "Content-Type": "application/json",
//             'Authorization': `Bearer ${_apiKey}`
//         }
//     }).then(
//         (res) => res.json()
//     )
// }

// export const fetchStripeAllCustomers = () => {
//     return fetch(_apiUrl+`/customers`, {
//         method: "GET",
//         headers: { 
//             "Content-Type": "application/json",
//             'Authorization': `Bearer ${_apiKey}`
//         }
//     }).then(
//         (res) => res.json()
//     )
// }

// export const fetchStripeCharges = () => {
//     return fetch(_apiUrl+"/charges", {
//         method: "GET",
//         headers: { 
//             "Content-Type": "application/json",
//             'Authorization': `Bearer ${_apiKey}`
//         }
//     }).then(
//         (res) => res.json()
//     )
// }

// export const fetchStripeLifetimeCharges = () => {
//     return fetch(_apiUrl+"/charges?amount=30000", {
//         method: "GET",
//         headers: { 
//             "Content-Type": "application/json",
//             'Authorization': `Bearer ${_apiKey}`
//         }
//     }).then(
//         (res) => res.json()
//     )
// }

// export const fetchStripeCustomerCharges = (customerid:string) => {
//     return fetch(_apiUrl+"/charges?customer="+customerid, {
//         method: "GET",
//         headers: { 
//             "Content-Type": "application/json",
//             'Authorization': `Bearer ${_apiKey}`
//         }
//     }).then(
//         (res) => res.json()
//     )
// }

// export const fetchSubscriptionActivity = (customerId:string) => {
//     return fetch(_apiUrl+`/subscriptions?customer=${customerId}`, {
//         method: "GET",
//         headers: { 
//             "Content-Type": "application/json",
//             'Authorization': `Bearer ${_apiKey}`
//         }
//     }).then(
//         (res) => res.json()
//     )
// }

//Subscription Customer ID: cus_QFI7P4vELCLWhW
//Customer ID for kxtnluc with real card:  cus_QFI7P4vELCLWhW
//Customer ID for kxtnluc with fake card:  cus_QFGv0y2HebjWEH
