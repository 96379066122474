import { Card, IconButton, TextField, Tooltip } from "@mui/material"
import SaveIcon from '@mui/icons-material/Save';

export const EditQuestion = (
    {
        question,
        userQuestion,
        responseStates,
        handleSave,
        handleInputChange,
        loggedInUser,
        i
    }:any
) => {
    return (
        <Card raised elevation={24} sx={{ '&:hover': { scale: 1.5 }, bgcolor: "#FCFAFF" }} className='q-card'>
            <div className='q-body'>
                {i + 1}: {question.body}
            </div>
            <div className='q-save'> {/*=================BELOW, checks if user has inputed any new info into the edit field=================*/}
                {userQuestion.response === responseStates[userQuestion.questionId] || responseStates[userQuestion.questionId] === '' || responseStates[userQuestion.questionId] === undefined ?
                    <Tooltip title='Save'>
                        <IconButton color='primary' size='large' disabled className='q-save-btn'><SaveIcon fontSize='large' /></IconButton>
                    </Tooltip>
                    :
                    <Tooltip title='Save'>
                        <IconButton color='primary' size='large' onClick={() => handleSave(loggedInUser.id, userQuestion)} className='q-save-btn'><SaveIcon fontSize='large' /></IconButton>
                    </Tooltip>
                }

            </div>
            <div className='q-response'>
                <TextField onChange={(e) => handleInputChange(e, userQuestion.questionId)} placeholder={userQuestion.response} className='q-response-input' size='small' type='text' value={responseStates[userQuestion.questionId] || ''}
                    onKeyUp={(e) => {
                        if (e.key === "Enter") handleSave(loggedInUser.id, userQuestion)
                    }}
                />
            </div>
        </Card>
    )
}