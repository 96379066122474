import { Card, IconButton, TextField, Tooltip } from "@mui/material"
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';

export const AddQuestion = (
    {
        questionToAdd,
        handleAdd,
        handleInputChange,
        responseStates,
        setAddQuestionMode
    }:any
) => {
    return (
        <div className='q-container'>
            <Card raised elevation={24} sx={{ '&:hover': { scale: 1.5 }, bgcolor: "#FCFAFF", }} className='q-card'>
                <div className='q-body'>
                    Add: {questionToAdd.body}
                </div>
                <div className='q-submit'>
                    <IconButton color='primary' size='small' onClick={() => handleAdd(questionToAdd, questionToAdd.id)} className='q-save-btn'><AddIcon fontSize='large' /></IconButton>
                </div>
                <div className='q-response'>
                    <TextField onChange={(e) => handleInputChange(e, questionToAdd.id)} className='q-response-input' size='small' type='text' value={responseStates[questionToAdd.id] || ''}
                        onKeyUp={(e) => {
                            if (e.key === "Enter") handleAdd(questionToAdd, questionToAdd.id)
                        }}
                    />
                </div>
            </Card>
            <Tooltip title="Close">
                <IconButton sx={{ marginTop: "0.7rem" }} className='q-add' onClick={() => setAddQuestionMode(false)}>
                    <CloseIcon sx={{ color: "lightgreen" }} className='q-add-btn' fontSize='large' />
                </IconButton>
            </Tooltip>
        </div>
    )
}