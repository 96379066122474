import { Card } from "@mui/material"
import SpeedDialComponent from "../../../misc/SpeedDialComponent"

export const SingleResponseView = ({question, setEditMode, setReload, reload, screenWidth}:any) => {
    console.log("user question 123:",question)

    const userQuestion = question.userQuestions[0]

    return (
        <div className='q-container'>
            <Card raised elevation={24} sx={{ '&:hover': { scale: 1.5 }, bgcolor: "#FCFAFF", height: "80px" }} className='q-card'>
                <div className='q-body' style={{ fontSize: "15px" }}>
                    {question.body}
                </div>
                {screenWidth <= 1350 ?
                    ("") :
                    (
                        <div className='q-save' style={{ marginTop: "-1.2rem" }}>
                            <SpeedDialComponent setEditMode={setEditMode} setReload={setReload} reload={reload} userQuestion={userQuestion} />
                        </div>
                    )}

                <div className='q-response' style={{ paddingBottom: "0.75rem", fontSize: "24px" }}>
                    {userQuestion.response !== undefined ? (userQuestion.response) : ("loading")}
                </div>
            </Card>

        </div>
    )
}





